<template>
	<MDBToast
		v-model="toastStore.toastObject.state"
		:delay="5000"
		autohide
		position="top-right"
		appendToBody
		stacking
		width="350px"
		:color="toastStore.toastObject.color"
		text="white"
		:icon="toastStore.toastObject.icon"
	>
		<template #title>
			{{ toastStore.toastObject.title }}
		</template>
		{{ toastStore.toastObject.message }}
	</MDBToast>
</template>

<script>
import { MDBToast } from "mdb-vue-ui-kit";
import { useToastStore } from "../../store/toastStore";
export default {
	components: {
		MDBToast,
	},
	setup() {
		const toastStore = useToastStore();

		return {
			toastStore,
		};
	},
};
</script>
